import React from 'react'
import PropTypes from 'prop-types'

import userImg from '../../images/person_1.jpg'
import { Col } from 'react-bootstrap'

const User = props => (
<Col md={3}>
    <div className='fh5co-team text-center'>
        <figure>
            <img src={userImg} alt='user' />
        </figure>
        <p>{props.name}
            <br />
            <span className='text-mute'>{props.info}</span>
        </p>
    </div>
</Col>
)

User.defaultProps = {
    name: 'en',
    info: ''
}

User.propTypes = {
    name: PropTypes.string,
    info: PropTypes.string
}

export default User
