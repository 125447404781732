import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, SectionGray } from '../components/sections'
import User from '../components/utils/user'

const Team = () => (
<Layout frame='teamNav'>

    <Section id='dev'>
        <Container>
            <Row className='row-bottom-padded-md'>
                <Col md={12} className='text-center heading-section'>
                    <h2>Team sviluppo</h2>
                    <p>
                        Il Team è composto da personale interno IAS e personale SUPSI
                    </p>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className='justify-content-md-center'>
                <User name='Federico Corboud' info='IAS / SUPSI-DEASS' />
                <User name='Jean Doe, XYZ Co.' info='' />
                <User name='Jean Doe, XYZ Co.' info='' />
                <User name='Domenico Ferrari' info='SUPSI-DEASS' />
                <User name='Mirko Cardoso' info='SUPSI-DEASS' />
                <User name='Massimiliano Cannata' info='SUPSI-DACD' />
            </Row>
        </Container>

    </Section>

    <SectionGray id='controllo'>
        <Container>
            <Row className='row-bottom-padded-md'>
                <Col md={12} className='text-center heading-section'>
                    <h2>Comitato di verifica</h2>
                    <p />
                </Col>
            </Row>
        </Container>

        <Container>
            <Row className='justify-content-md-center'>
                <User name='Sergio Montorfani' info='Direttore IAS' />
                <User name='Maurizio Pelli' info='Vicedirettore IAS' />
            </Row>

            <Row className='justify-content-md-center'>
                <User name='Franco Gervasoni' info='Direttore SUPSI' />
                <User name='Luca Crivelli' info='Direttore DEASS' />
                <User name='Silvio Seno' info='Direttore IST' />
            </Row>
        </Container>

    </SectionGray>

    <Section id='organigramma'>
        <Container>
            <Row className='row-bottom-padded-md'>
                <Col md={{ span: 8, offset: 2 }} className='text-center heading-section'>
                    <h2>Organigramma</h2>
                    <p />
                </Col>
            </Row>

            <Row className='row-bottom-padded-md'>
                <Col md={12} className='text-center' />
            </Row>
        </Container>
    </Section>

</Layout>

)

export default Team
